import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { ModalsController, ToolbarButton } from '@grafana/ui';
import { featureEnabled, reportExperimentView } from '@grafana/runtime';
import { addCustomRightAction } from 'app/features/dashboard/components/DashNav/DashNav';
import { UpgradeModal } from 'app/core/components/Upgrade/UpgradeModal';
import { DashboardModel } from 'app/features/dashboard/state';
import { EnterpriseStoreState } from '../../types';
import { setDrawerOpen } from '../state/reducers';
import AnalyticsDrawer from './AnalyticsDrawer';
import { buildExperimentID, ExperimentGroup } from '../../utils/featureHighlights';

type AnalyticsToolbarButtonProps = {
  onClick(): void;
  isHighlighted?: boolean;
};

const AnalyticsToolbarButton = ({ onClick, isHighlighted }: AnalyticsToolbarButtonProps) => {
  return (
    <ToolbarButton icon="info-circle" tooltip="Dashboard insights" onClick={onClick} isHighlighted={isHighlighted} />
  );
};

type AnalyticsContentProps = {
  dashboard?: DashboardModel;
  isDrawerOpen: boolean;
  setDrawerOpen: typeof setDrawerOpen;
};

const AnalyticsContent: FC<AnalyticsContentProps> = ({ dashboard, isDrawerOpen, setDrawerOpen }) => {
  return (
    dashboard?.id &&
    dashboard.meta.url && (
      <>
        <AnalyticsToolbarButton
          onClick={() => {
            setDrawerOpen(true);
          }}
        />
        {isDrawerOpen && <AnalyticsDrawer dashboard={dashboard} />}
      </>
    )
  );
};

function mapStateToProps(state: EnterpriseStoreState) {
  return {
    isDrawerOpen: state.metaAnalytics.isDrawerOpen,
  };
}

const mapDispatchToProps = {
  setDrawerOpen,
};

type AnalyticsContentUpgradeProps = {
  dashboard?: DashboardModel;
};

const AnalyticsContentUpgrade = ({ dashboard }: AnalyticsContentUpgradeProps) => {
  const showContent = dashboard?.id && dashboard.meta.url;

  useEffect(() => {
    if (showContent) {
      reportExperimentView(buildExperimentID('dashboard-insights-dot'), ExperimentGroup.Test, '');
    }
  }, [showContent]);

  return (
    showContent && (
      <ModalsController key="button-insights">
        {({ showModal, hideModal }) => (
          <AnalyticsToolbarButton
            onClick={() => {
              showModal(UpgradeModal, {
                title: 'Dashboard insights',
                text: `Usage Insights provide detailed information about dashboard and data source usage, like the number of views, queries, and errors users have experienced. You can use this to improve users' experience and troubleshoot issues.`,
                isOpen: true,
                onDismiss: hideModal,
                experimentId: buildExperimentID('dashboard-insights'),
              });
            }}
            isHighlighted={true}
          />
        )}
      </ModalsController>
    )
  );
};

export const initAnalyticsDrawer = () => {
  addCustomRightAction({
    show: () => true,
    component: featureEnabled('analytics')
      ? connect(mapStateToProps, mapDispatchToProps)(AnalyticsContent)
      : AnalyticsContentUpgrade,
    index: -1,
  });
};
