import React, { useEffect } from 'react';
import { reportExperimentView } from '@grafana/runtime';
import Page from 'app/core/components/Page/Page';
import { connector, Props } from './DataSourceCache';
import { UpgradeBox } from 'app/core/components/Upgrade/UpgradeBox';
import { buildExperimentID, ExperimentGroup } from '../utils/featureHighlights';

export const DataSourceUpgradePage = ({ navModel, pageId, loadDataSource, loadDataSourceMeta }: Props) => {
  useEffect(() => {
    loadDataSource(pageId).then(loadDataSourceMeta);
  }, [pageId, loadDataSourceMeta, loadDataSource]);

  useEffect(() => {
    reportExperimentView(buildExperimentID('query-caching'), ExperimentGroup.Test, '');
  }, []);

  return (
    <Page navModel={navModel}>
      <Page.Contents>
        <UpgradeBox
          text={
            'With Query Caching, you can load dashboards faster and reduce costly queries to data sources by temporarily storing query results in memory, Redis, or Memcachd.'
          }
        />
      </Page.Contents>
    </Page>
  );
};

export default connector(DataSourceUpgradePage);
