import React, { useEffect } from 'react';
import { reportExperimentView } from '@grafana/runtime';
import Page from 'app/core/components/Page/Page';
import { UpgradeBox } from 'app/core/components/Upgrade/UpgradeBox';
import { connector, Props } from './AccessControlDataSourcePermissions';
import { buildExperimentID, ExperimentGroup } from '../utils/featureHighlights';

export const UpgradePage = ({ navModel, loadDataSource, loadDataSourceMeta, resourceId }: Props) => {
  useEffect(() => {
    loadDataSource(resourceId as any).then(loadDataSourceMeta);
  }, [resourceId, loadDataSource, loadDataSourceMeta]);

  useEffect(() => {
    reportExperimentView(buildExperimentID('data-source-permissions'), ExperimentGroup.Test, '');
  }, []);

  return (
    <Page navModel={navModel}>
      <Page.Contents>
        <UpgradeBox
          text={
            'With data source permissions, you can protect sensitive data by limiting access to this data source to specific users, teams, and roles.'
          }
        />
      </Page.Contents>
    </Page>
  );
};

export default connector(UpgradePage);
