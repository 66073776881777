import { featureEnabled } from '@grafana/runtime';
import { addDashboardShareTab, ShareModalTabModel } from 'app/features/dashboard/components/ShareModal';
import { contextSrv } from 'app/core/services/context_srv';
import { config } from 'app/core/config';
import { ProBadge } from 'app/core/components/Upgrade/ProBadge';
import { SharePDF } from './SharePDF';
import { CreateReportTab } from './CreateReportTab';
import { AccessControlAction } from '../types';
import { buildExperimentID } from '../utils/featureHighlights';

const highlightsEnabled = config.featureToggles.featureHighlights;
const sharePDFTab: ShareModalTabModel = {
  label: 'PDF',
  value: 'pdf',
  component: SharePDF,
};

const createReportTab: ShareModalTabModel = {
  label: 'Report',
  value: 'report',
  tabSuffix:
    highlightsEnabled && !featureEnabled('reports.creation')
      ? () => ProBadge({ experimentId: buildExperimentID('reporting-tab-badge') })
      : undefined,
  component: CreateReportTab,
};

export function initReporting() {
  if (featureEnabled('reports.creation')) {
    addDashboardShareTab(sharePDFTab);

    if (contextSrv.hasAccess(AccessControlAction.ReportingAdminCreate, contextSrv.hasRole('Admin'))) {
      addDashboardShareTab(createReportTab);
    }
  } else if (highlightsEnabled) {
    addDashboardShareTab(createReportTab);
  }
}
