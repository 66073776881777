import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { reportExperimentView } from '@grafana/runtime';
import { UpgradeBox } from 'app/core/components/Upgrade/UpgradeBox';
import Page from 'app/core/components/Page/Page';
import { getNavModel } from 'app/core/selectors/navModel';
import { EnterpriseStoreState } from '../types';
import { buildExperimentID, ExperimentGroup } from '../utils/featureHighlights';

function mapStateToProps(state: EnterpriseStoreState) {
  return {
    navModel: getNavModel(state.navIndex, 'reports'),
  };
}

const connector = connect(mapStateToProps);
export type Props = ConnectedProps<typeof connector>;

const ReportsUpgradePage = ({ navModel }: Props) => {
  useEffect(() => {
    reportExperimentView(buildExperimentID('reporting'), ExperimentGroup.Test, '');
  }, []);

  return (
    <Page navModel={navModel}>
      <Page.Contents>
        <UpgradeBox
          text={
            'With reporting you can deliver dashboard PDFs by email on a schedule to users who don’t regularly look at Grafana.\n' +
            "You'll need to upgrade to a Pro Plan to access reporting with Grafana Cloud."
          }
          secondaryAction={{
            url: 'https://grafana.com/docs/grafana/latest/enterprise/reporting/',
            text: 'Learn more about reporting',
          }}
        >
          <UpgradeBoxContent />
        </UpgradeBox>
      </Page.Contents>
    </Page>
  );
};

const UpgradeBoxContent = () => {
  const styles = useStyles2(getStyles);
  return (
    <ul className={styles.content}>
      <li>Customize your exact layout and orientation</li>
      <li>Personalize with your unique branding</li>
      <li>Specify permissions for users across your company</li>
      <li>Choose to send a report at custom intervals</li>
    </ul>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    content: css`
      list-style: none;
      columns: 2;
      margin-bottom: ${theme.spacing(2)};

      li {
        line-height: 2;

        &::before {
          content: '\\2713';
          margin-right: ${theme.spacing(1)};
        }
      }
    `,
  };
};
export default connector(ReportsUpgradePage);
