import React, { useEffect } from 'react';
import { reportExperimentView } from '@grafana/runtime';
import Page from 'app/core/components/Page/Page';
import { UpgradeBox } from 'app/core/components/Upgrade/UpgradeBox';
import { connector, Props } from './DataSourceInsights';
import { buildExperimentID, ExperimentGroup } from '../../utils/featureHighlights';

export const DataSourceUpgradePage = ({
  navModel,
  dataSourceUid,
  loadDataSource,
  loadDataSourceMeta,
}: Omit<Props, 'theme'>) => {
  useEffect(() => {
    loadDataSource(dataSourceUid).then(loadDataSourceMeta);
  }, [dataSourceUid, loadDataSource, loadDataSourceMeta]);

  useEffect(() => {
    reportExperimentView(buildExperimentID('data-source-insights'), ExperimentGroup.Test, '');
  }, []);

  return (
    <Page navModel={navModel}>
      <Page.Contents>
        <UpgradeBox
          text={
            'Usage Insights provide detailed information about dashboard and data source usage, like the number of views, queries, and errors users have experienced. You can use this to improve users’ experience and troubleshoot issues.'
          }
        />
      </Page.Contents>
    </Page>
  );
};

export default connector(DataSourceUpgradePage);
